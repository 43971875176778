
















































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
import {
  getAssessGroupTemplates,
  addAssess,
  createReport,
} from "@/request/custom";
@Component({
  components: {
    MainHeader,
    NavLeft,
  },
})
export default class Name extends Vue {
  private data: any = {};
  private get patient() {
    return this.$store.state.patient;
  }
  private type: any = "";
  private goReport() {
    const params: any = {
      patient_id: this.patient.patient_id,
      评估名称: this.type,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    createReport(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.$router.push({
          path: "/main/workstation/patient-pinggu-report",
          query: {
            id: res,
          },
        });
      })
      .catch(() => {
        loading.close();
      });
  }
  private goPinggu() {
    let template_ids: any = [];
    this.data.templates.forEach((ele: any) => {
      if (ele.checked) {
        template_ids.push(ele.template_id);
      }
    });
    if (template_ids.length === 0) {
      this.$message.warning("请选择评估内容");
      return;
    }
    const params: any = {
      template_ids: template_ids,
      patient_id: this.patient.patient_id,
      assess_group_id: this.data.id,
      测评时间: "",
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    addAssess(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.$store.commit("updateIfCompose", true);
        this.$router.push({
          path: "/main/workstation/patient-pinggu-edit",
          query: {
            type: this.type,
            id: res.assess_id,
          },
        });
      })
      .catch(() => {
        loading.close();
      });
  }
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      类别: this.type,
    };
    getAssessGroupTemplates(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private mounted() {
    this.type = this.$route.query.type;
    this.getData();
  }
}
